// Import Custom Bootswatch Mixins and Components
@import "variables.scss";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap.scss";

// Import fonts
@import "~@fontsource/roboto/index.css"; // Weight 400.
@import "~@fontsource/roboto/700.css"; // Weight 700.

// Import FontAwesome
//@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
//@import "~@fortawesome/fontawesome-free/scss/solid.scss";

.nav-tabs,
.nav-pills,
.category-tabs {
  .nav-link {
    background-color: transparent;
    color: $primary;
    text-transform: uppercase;
  
    &:hover {
      background-color: $gray-700;
    }

    &.disabled,
    &.disabled:hover {
      background-color: transparent;
      color: $nav-link-disabled-color;
    }

    &.active {
      color: $warning;
      background-color: transparent;
    }
  }
}

.btn-template {
  font-size: .85rem;
}

.cruise-details, .lowering-details {
  font-size: .75rem;
}

.eventList {
  overflow-y: scroll;
  font-size: .8rem;

  &:focus { outline: none; }

  &.collapsed { height: 220px; }
}

.event-data-card {
  height: 100%;

  border-color: $secondary;
  .card-header {
    padding: .25rem .5rem !important;
    text-transform: capitalize;
  }

  .card-body {
    font-size: .8rem;
    padding: .5rem;

    .data-name {
      text-transform: capitalize;
    }
  }
}

.event-image-data-card {
  border: none;
  background-color: transparent;
  text-transform: capitalize;
}

// Utility class to hide arrow from dropdown
.dropdown.no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}

// react-datetime styles
@import "~react-datetime/css/react-datetime.css";

// react-datetime customizations

.rdtPicker {
  background: $body-bg;
  border: 1px solid $secondary;
  color: $white;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: $white;
  color: $body-bg;
}

.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid $primary;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: $primary;
  color: $white;
}

.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: $secondary;
}

.rdtPicker th {
  border-bottom: 1px solid $secondary;
}

.rdtPicker thead tr:first-child th:hover {
  background: $white;
  color: $body-bg;
}

.rdtPicker tfoot {
  border-top: 1px solid $secondary;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background: $white;
  color: $body-bg;
}

.rdtPicker th {
  border-bottom: 1px solid $secondary;
}

.rdtCounter .rdtBtn:hover {
  background: $white;
  color: $body-bg;
}
